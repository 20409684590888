import { styled } from '@mui/material/styles';

const MatIcon = styled('div')(({ theme }) => ({
    userSelect: 'none',
    backgroundRepeat: 'no-repeat',
    display: 'inline-block',
    fill: 'currentColor',
    height: '24px',
    width: '24px',
    overflow: 'hidden'
}));

export default MatIcon;