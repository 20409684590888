import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import "./style.scss";

const DarkTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props}
        classes={{ popper: className }}
        leaveDelay={200}
        slotProps={{
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -7],
                        },
                    },
                ],
            },
        }}
    />
))(({ theme }) => {
    return {
        [`&.${tooltipClasses.tooltip}`]: {
            backgroundColor: '#616161',
            color: '#ffffff'
        }
    }
});

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => {
    console.log(tooltipClasses);

    return {
        /*
        [`&.${tooltipClasses.root}`]: {
            backgroundColor: '#191919',
            color: '#ffffff'
        },
        */
        [`& .${tooltipClasses.arrow}`]: {
            color: '#191919',
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#191919',
            color: '#ffffff'
        }
    }
});

export default DarkTooltip;