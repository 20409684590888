import "./navbar.scss";
import React, { useState, useContext, useEffect } from "react";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

import { DarkModeContext } from "../../../context/darkModeContext.jsx";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { useLocation } from "react-router-dom";
import { TextField } from "@mui/material";
import i18n from "i18next";
import Flag from "../../flags/Flags";
// import BRFlag from "../../../../public/flags/br.svg";
// import USFlag from "../../../../public/flags/us.svg";
import { useTranslation } from "react-i18next";

import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useQuery } from "react-query";
import { getBalance } from "../../../services/TransactionsService";
import { MdVisibility } from "react-icons/md";
import { MdVisibilityOff } from "react-icons/md";
import CircularProgress from "@mui/material/CircularProgress";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterMetrics from "../../filterMetrics/FilterMetrics.jsx";
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';

import Tooltip from "@/components/tooltip";
// import Button from "@/components/Button";
import MatIcon from "@/components/MatIcon";
import HeaderInfo from "@/components/HeaderInfo";

import { logout } from "../../../store/features/auth/slice.js";

const Button = styled('button')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'none',
    border: 'none',
    margin: '0 7px',
    width: '30px',
    '&.light-icon': {
        color: '#99a1b7'
    }
}));

const MenuHeader = styled('header')(({ theme }) => ({
    alignItems: 'center',
    borderBottom: '1px solid #dbdfe9',
    display: 'flex',
    margin: '20px',
    padding: '0 20px 10px',
    'span': {
        // fontSize: '16px',
        // fontWeight: 600,
        marginLeft: '90px',
        padding: '2px 7px',
        // width: '300px',
        borderRadius: '7px',
        fontSize: '11px',
        fontWeight: 600,
        right: 10,
        position: 'absolute',
        top: 10,
        '&.enable': {
            backgroundColor: '#dfffea',
            color: '#3bc97c'
        }
    },
    '.header-dropdown__img': {
        marginRight: '15px',
        width: '45px'
    },
    '.header-dropdown__name': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '16px',
        fontWeight: 600,
        margin: 0
    },
    '.header-dropdown__email': {
        color: '#99a1b7',
        fontSize: '12px',
        fontWeight: 600,
        margin: 0
    },
}));

const Item = styled(MenuItem)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 500,
    padding: '5px 10px',
    margin: '10px 15px',
    transition: 'color .2s ease',
    '&:hover': {
        backgroundColor: '#f9f9f9',
        borderRadius: '10px',
        color: 'var(--primary)',
        transition: 'color .2s ease'
    }
}));

const Navbar = ({ onFilterData }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [hasShadow, setHasShadow] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setHasShadow(true);
            } else {
                setHasShadow(false);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Box sx={{ position: 'fixed', width: '100%', zIndex: 2 }}>
            {/* <HeaderInfo /> */}
            <header className="header header-light" style={{ boxShadow: hasShadow ? 'rgba(0, 0, 0, 0.2) 2px 4px 15px 1px' : 'none', backdropFilter: 'none' }}>
                <nav className="nav">
                    <Button className="light-icon" onClick={() => navigate('/pix/transfer')}>
                        <Tooltip title="Fazer Pix" placement="bottom">
                            <MatIcon className="mat-icon" role="img" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="pix">
                                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                    <g clipPath="url('/dashboard#clip0_1_28')">
                                        <path d="M18.1905 17.8565C17.322 17.8565 16.508 17.5225 15.8932 16.9076L12.5814 13.5959C12.3538 13.3682 11.9406 13.3682 11.713 13.5959L8.3876 16.9212C7.77276 17.5361 6.95874 17.8701 6.09029 17.8701H5.43585L9.64327 22.0775C10.9521 23.3864 13.0886 23.3864 14.3975 22.0775L18.6173 17.8577L18.1905 17.8565Z" fill="currentColor"></path>
                                        <path d="M6.07667 6.1299C6.94512 6.1299 7.75915 6.46392 8.37399 7.07876L11.6994 10.4041C11.9394 10.6441 12.3266 10.6441 12.5678 10.4041L15.8932 7.09237C16.508 6.47753 17.322 6.14351 18.1905 6.14351H18.5913L14.3715 1.92371C13.0627 0.614846 10.9262 0.614846 9.61729 1.92371L5.40863 6.1299H6.07667Z" fill="currentColor"></path>
                                        <path d="M22.0763 9.62969L19.5253 7.07876C19.4722 7.10598 19.4053 7.11835 19.3385 7.11835H18.1769C17.5757 7.11835 16.988 7.35835 16.5748 7.78639L13.2618 11.0981C12.955 11.4049 12.5406 11.5658 12.1398 11.5658C11.7253 11.5658 11.3245 11.4049 11.0177 11.0981L7.69236 7.77278C7.26555 7.34598 6.67792 7.10474 6.09029 7.10474H4.66143C4.59462 7.10474 4.54143 7.09113 4.48823 7.06516L1.9237 9.62969C0.614831 10.9386 0.614831 13.0751 1.9237 14.3839L4.47462 16.9348C4.52782 16.9076 4.58102 16.8953 4.64782 16.8953H6.07669C6.67792 16.8953 7.26555 16.6553 7.67875 16.2272L11.0041 12.9019C11.6053 12.3006 12.6606 12.3006 13.2606 12.9019L16.5724 16.2136C16.9992 16.6404 17.5868 16.8817 18.1744 16.8817H19.3361C19.4029 16.8817 19.4561 16.8953 19.5229 16.9212L22.0738 14.3703C23.3851 13.0614 23.3851 10.9386 22.0763 9.62969Z" fill="currentColor"></path>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_1_28">
                                            <rect width="24" height="24" fill="currentColor"></rect>
                                        </clipPath>
                                    </defs>
                                </svg>
                            </MatIcon>
                        </Tooltip>
                    </Button>
                    <Button className="light-icon" onClick={() => navigate(`/deposit`)}>
                        <Tooltip title="Depositar em sua conta" placement="bottom">
                            <MatIcon className="mat-icon" role="img" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="pix">
                                <svg width="100%" height="100%" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                    <path d="M5.5 6.875C5.5 6.11561 6.11561 5.5 6.875 5.5H9.625C10.3844 5.5 11 4.88439 11 4.125C11 3.36562 10.3844 2.75 9.625 2.75H6.875C4.59683 2.75 2.75 4.59683 2.75 6.875V9.625C2.75 10.3844 3.36562 11 4.125 11C4.88439 11 5.5 10.3844 5.5 9.625V6.875Z" fill="currentColor"></path>
                                    <path d="M23.375 2.75C22.6156 2.75 22 3.36562 22 4.125C22 4.88439 22.6156 5.5 23.375 5.5H26.125C26.8844 5.5 27.5 6.11561 27.5 6.875V9.625C27.5 10.3844 28.1156 11 28.875 11C29.6344 11 30.25 10.3844 30.25 9.625V6.875C30.25 4.59683 28.4032 2.75 26.125 2.75H23.375Z" fill="currentColor"></path>
                                    <path d="M5.5 23.375C5.5 22.6156 4.88439 22 4.125 22C3.36562 22 2.75 22.6156 2.75 23.375V26.125C2.75 28.4032 4.59683 30.25 6.875 30.25H9.625C10.3844 30.25 11 29.6344 11 28.875C11 28.1156 10.3844 27.5 9.625 27.5H6.875C6.11561 27.5 5.5 26.8844 5.5 26.125V23.375Z" fill="currentColor"></path>
                                    <path d="M30.25 23.375C30.25 22.6156 29.6344 22 28.875 22C28.1156 22 27.5 22.6156 27.5 23.375V26.125C27.5 26.8844 26.8844 27.5 26.125 27.5H23.375C22.6156 27.5 22 28.1156 22 28.875C22 29.6344 22.6156 30.25 23.375 30.25H26.125C28.4032 30.25 30.25 28.4032 30.25 26.125V23.375Z" fill="currentColor"></path>
                                    <g opacity="0.3">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M8.25 15.125C7.49062 15.125 6.875 14.5094 6.875 13.75V8.25C6.875 7.49062 7.49062 6.875 8.25 6.875H13.75C14.5094 6.875 15.125 7.49062 15.125 8.25V13.75C15.125 14.5094 14.5094 15.125 13.75 15.125H8.25ZM9.625 9.625V12.375H12.375V9.625H9.625Z" fill="currentColor"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M6.875 24.75C6.875 25.5094 7.49062 26.125 8.25 26.125H13.75C14.5094 26.125 15.125 25.5094 15.125 24.75V19.25C15.125 18.4906 14.5094 17.875 13.75 17.875H8.25C7.49062 17.875 6.875 18.4906 6.875 19.25V24.75ZM9.625 23.375V20.625H12.375V23.375H9.625Z" fill="currentColor"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M19.25 15.125C18.4906 15.125 17.875 14.5094 17.875 13.75V8.25C17.875 7.49062 18.4906 6.875 19.25 6.875H24.75C25.5094 6.875 26.125 7.49062 26.125 8.25V13.75C26.125 14.5094 25.5094 15.125 24.75 15.125H19.25ZM20.625 9.625V12.375H23.375V9.625H20.625Z" fill="currentColor"></path>
                                    </g>
                                    <path opacity="0.3" d="M17.875 17.875H20.625V20.625H17.875V17.875Z" fill="currentColor"></path>
                                    <path opacity="0.3" d="M23.375 20.625H20.625V23.375H17.875V26.125H20.625V23.375H23.375V20.625Z" fill="currentColor"></path>
                                    <path opacity="0.3" d="M23.375 20.625V17.875H26.125V20.625H23.375Z" fill="currentColor"></path>
                                </svg>
                            </MatIcon>
                        </Tooltip>
                    </Button>
                    <Button className="light-icon">
                        <Tooltip title="Transferência interna" placement="bottom">
                            <MatIcon className="mat-icon" role="img" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="pix">
                                <svg id="Capa_1" enableBackground="new 0 0 512 512" height="100%" viewBox="0 0 512 512" width="100%" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                    <path d="m256 128.533c-70.285 0-127.467 57.182-127.467 127.467s57.182 127.467 127.467 127.467 127.467-57.182 127.467-127.467-57.182-127.467-127.467-127.467zm0 224.934c-53.743 0-97.467-43.724-97.467-97.467s43.724-97.467 97.467-97.467 97.467 43.724 97.467 97.467-43.724 97.467-97.467 97.467z"></path>
                                    <path d="m260.028 32.184 6.578-6.578c5.858-5.857 5.858-15.355 0-21.213-5.857-5.858-15.355-5.857-21.213 0l-32.133 32.134c-2.813 2.813-4.394 6.628-4.394 10.606s1.58 7.793 4.394 10.606l32.134 32.134c2.929 2.929 6.768 4.393 10.606 4.393s7.678-1.464 10.606-4.394c5.858-5.858 5.858-15.355 0-21.213l-6.475-6.474c104.998 2.208 189.735 88.298 189.735 193.815 0 8.284 6.716 15 15 15s15-6.716 15-15c0-122.094-98.25-221.657-219.838-223.816z"></path>
                                    <path d="m264.033 241h-15.745c-5.195 0-9.422-4.241-9.422-9.469 0-4.814 3.902-8.731 8.698-8.731h24.502c8.284 0 15-6.716 15-15s-6.716-15-15-15h-1.066v-1.066c0-8.284-6.716-15-15-15s-15 6.716-15 15v1.635c-18.221 3.131-32.134 19.048-32.134 38.178 0 21.755 17.685 39.454 39.422 39.454h15.745c5.019 0 9.101 4.083 9.101 9.1s-4.082 9.1-9.101 9.1h-24.1c-8.284 0-15 6.716-15 15s6.716 15 15 15h1.067v1.066c0 8.284 6.716 15 15 15s15-6.716 15-15v-1.7c18.246-3.297 32.134-19.285 32.134-38.466 0-21.561-17.54-39.101-39.101-39.101z"></path>
                                    <path d="m266.606 422.126c-5.857-5.857-15.355-5.858-21.213 0s-5.858 15.355 0 21.213l6.475 6.474c-104.998-2.207-189.734-88.296-189.734-193.813 0-8.284-6.716-15-15-15s-15 6.716-15 15c0 122.094 98.25 221.657 219.838 223.816l-6.578 6.578c-5.858 5.857-5.858 15.355 0 21.213 2.928 2.928 6.767 4.393 10.606 4.393s7.678-1.465 10.606-4.393l32.134-32.134c2.813-2.813 4.394-6.628 4.394-10.606s-1.58-7.793-4.394-10.606z"></path>
                                </svg>
                            </MatIcon>
                        </Tooltip>
                    </Button>
                    <Button className="light-icon">
                        <Tooltip title="Modo escuro" placement="bottom">
                            <MatIcon className="mat-icon" role="img" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="pix">
                                <svg width="100%" height="100%" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                    <path d="M19.0647 5.43757C19.3421 5.43757 19.567 5.21271 19.567 4.93534C19.567 4.65796 19.3421 4.43311 19.0647 4.43311C18.7874 4.43311 18.5625 4.65796 18.5625 4.93534C18.5625 5.21271 18.7874 5.43757 19.0647 5.43757Z" fill="currentColor"></path>
                                    <path d="M20.0692 9.48884C20.3466 9.48884 20.5714 9.26398 20.5714 8.98661C20.5714 8.70923 20.3466 8.48438 20.0692 8.48438C19.7918 8.48438 19.567 8.70923 19.567 8.98661C19.567 9.26398 19.7918 9.48884 20.0692 9.48884Z" fill="currentColor"></path>
                                    <path d="M12.0335 20.5714C15.6943 20.5714 18.9426 18.2053 20.1168 14.7338C20.1884 14.5225 20.1114 14.289 19.9284 14.161C19.746 14.034 19.5003 14.0418 19.3257 14.1821C18.2432 15.0546 16.9371 15.5156 15.5491 15.5156C12.2257 15.5156 9.48886 12.8122 9.48886 9.48886C9.48886 7.41079 10.5773 5.47137 12.3449 4.35752C12.5342 4.23832 12.6 4.00733 12.5377 3.79251C12.4759 3.57768 12.2571 3.42859 12.0335 3.42859C7.32558 3.42859 3.42859 7.29209 3.42859 12C3.42859 16.7079 7.32558 20.5714 12.0335 20.5714Z" fill="currentColor"></path>
                                    <path d="M13.0378 7.47998C13.8687 7.47998 14.5445 8.15585 14.5445 8.98668C14.5445 9.26428 14.7692 9.48891 15.0468 9.48891C15.3244 9.48891 15.549 9.26428 15.549 8.98668C15.549 8.15585 16.2249 7.47998 17.0557 7.47998C17.3333 7.47998 17.5579 7.25535 17.5579 6.97775C17.5579 6.70015 17.3333 6.47552 17.0557 6.47552C16.2249 6.47552 15.549 5.76616 15.549 4.93534C15.549 4.65774 15.3244 4.43311 15.0468 4.43311C14.7692 4.43311 14.5445 4.65774 14.5445 4.93534C14.5445 5.76616 13.8687 6.47552 13.0378 6.47552C12.7602 6.47552 12.5356 6.70015 12.5356 6.97775C12.5356 7.25535 12.7602 7.47998 13.0378 7.47998Z" fill="currentColor"></path>
                                </svg>
                            </MatIcon>
                        </Tooltip>
                    </Button>
                    <Button className="light-icon">
                        <Tooltip title="Esconder saldo" placement="bottom">
                            <MatIcon className="mat-icon" role="img" aria-hidden="true" data-mat-icon-type="svg" data-mat-icon-name="pix">
                                <svg id="Icons" enableBackground="new 0 0 128 128" height="100%" viewBox="0 0 128 128" width="100%" xmlns="http://www.w3.org/2000/svg" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                    <path id="Show" d="m64 104c-41.873 0-62.633-36.504-63.496-38.057-.672-1.209-.672-2.678 0-3.887.863-1.552 21.623-38.056 63.496-38.056s62.633 36.504 63.496 38.057c.672 1.209.672 2.678 0 3.887-.863 1.552-21.623 38.056-63.496 38.056zm-55.293-40.006c4.758 7.211 23.439 32.006 55.293 32.006 31.955 0 50.553-24.775 55.293-31.994-4.758-7.211-23.439-32.006-55.293-32.006-31.955 0-50.553 24.775-55.293 31.994zm55.293 24.006c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"></path>
                                </svg>
                            </MatIcon>
                        </Tooltip>
                    </Button>
                    <div container="body" ngbdropdown="" className="dropdown">
                        <button id="dropdownBasic" ngbdropdowntoggle="" className="dropdown-toggle btn btn-img" aria-expanded="false" onClick={handleClick}>
                            <img src="/profile.png" alt="Imagem de perfil do usuário" />
                        </button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuHeader>
                                <span className="enable">Mfa Habilitado</span>
                                <img className="header-dropdown__img" src="/profile.png" alt="Imagem de perfil do usuário" />
                                <Box>
                                    <h2 className="header-dropdown__name"> Adm </h2>
                                    <h3 className="header-dropdown__email">math_santos@null.net</h3>
                                </Box>
                            </MenuHeader>
                            <Item onClick={() => navigate(`/user-settings`)}>Perfil</Item>
                            <Item onClick={() => navigate(`/user-settings/reset-password`)}>Alterar Senha</Item>
                            <Item onClick={() => dispatch(logout())}>Sair</Item>
                        </Menu>
                    </div>
                </nav>
            </header>
        </Box>
    );
};

export default Navbar;
