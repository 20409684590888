import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from "redux-persist";
// import AsyncStorage from '@react-native-async-storage/async-storage';
import storage from "redux-persist/lib/storage";
import logger from 'redux-logger';

import authReducer from './features/auth/slice';
import userReducer from './features/user/slice';
import applicationReducer from './features/apps/slice';
import cashoutReducer from './features/cashout/slice';

const config = {
    key: "root",
    storage: storage
};

const baseReducer = combineReducers({ 
    auth: authReducer,
    user: userReducer,
    app: applicationReducer,
    cashout: cashoutReducer
});

const persistedReducer = persistReducer(config, baseReducer);

/*
export const store = configureStore({
    reducer: {
        auth: authReducer,
        user: userReducer,
        app: applicationReducer,
        cashout: cashoutReducer,
    }
});
*/

let middlewares = [];

if (process.env.NODE_ENV == 'development') {
    middlewares.push(logger);
}

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: {
                ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
            },
        }).concat(middlewares),
});

export const persistor = persistStore(store);