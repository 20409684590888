import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    zIndex: '2001 !important',
    [`& .${tooltipClasses.arrow}`]: {
        color: '#191919',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#191919',
        color: '#ffffff'
    }
}));

export default CustomTooltip;