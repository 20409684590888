import React, { useContext, useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

// import Home from "./pages/home/Home";
// import Transactions from "./pages/list/List";
// import Receipts from "./pages/receipts/Receipts";
// import Payments from "./pages/payments/Payments";
// import Profile from "./pages/profile/Profile";

// import Login from "./components/login/Login";
// import Loginexa from "./components/login/Loginexa";
// import Login from "./components/login/Loginexb";
// import Register from "./components/register/Register";
// import Recovery from "./components/recovery/Recovery";

// import Admin from "./pages/admin/Index";
// import UserList from "./pages/admin/UserList";
// import UserProfile from "./pages/admin/UserProfile";

// import HomeV2 from "./pages/v2/home/Home";
// import Statement from "./pages/v2/statement";

const Login = React.lazy(() => import('./pages/v2/auth/login'));
const Register = React.lazy(() => import('./pages/v2/auth/register'));
const MFA = React.lazy(() => import('./pages/v2/auth/mfa'));
const Recovery = React.lazy(() => import('./pages/v2/password/recovery'));
const HomeV2 = React.lazy(() => import('./pages/v2/home/Home'));
const Statement = React.lazy(() => import('./pages/v2/statement'));
const Payments = React.lazy(() => import('./pages/v2/payments'));
const Withdrawal = React.lazy(() => import('./pages/v2/withdrawal'));
const WithdrawalDetail = React.lazy(() => import('./pages/v2/withdrawal/detail'));
const Crypto = React.lazy(() => import('./pages/v2/crypto'));
const Easycard = React.lazy(() => import('./pages/v2/easycard'));
const IntegrationsApp = React.lazy(() => import('./pages/v2/integrations/app'));
const SecurityToken = React.lazy(() => import('./pages/v2/integrations/security-token'));
const CreateMultiFactor = React.lazy(() => import('./pages/v2/settings/create-multi-factor'));
const SecurityPassword = React.lazy(() => import('./pages/v2/settings/security-password'));
const UserSetting = React.lazy(() => import('./pages/v2/user-settings'));
const ChangePassword = React.lazy(() => import('./pages/v2/user-settings/reset-password'));

const PixTransfer = React.lazy(() => import('./pages/v2/transfer/pix'));
const Deposit = React.lazy(() => import('./pages/v2/deposit'));
const Checkout = React.lazy(() => import('./pages/v2/checkout'));

// import PrivateRoute from './PrivateRoute';

// import "./style/dark.scss";
import "./App.scss";
// import './style.scss';

import LoadScreen from "./components/loader";
import { DarkModeContext } from "./context/darkModeContext";
import { useAuth, ProvideAuth, PrivateRoute, PublicRoute } from "./context/authContext";
import { checkUser } from '@/store/features/auth/slice';

const queryClient = new QueryClient();

NProgress.configure({
    showSpinner: false
})

const LazyLoad = () => {
    useEffect(() => {
        NProgress.start();

        return () => {
            NProgress.done();
        };
    });

    return '';
};

function App() {
    const dispatch = useDispatch();
    const { token, isAuthenticated, validate } = useSelector((state) => state.auth);

    useEffect(() => {
        if (token && validate.length <= 0) {
            dispatch(checkUser(token));
        }
    }, [token, dispatch]);

    return (        
        <QueryClientProvider client={queryClient}>
            <ProvideAuth>
                <BrowserRouter>
                    <Suspense fallback={<LazyLoad />}>
                        <Routes>
                            {/*
                            <Route path="/" element={<PublicRoute/>}>
                                <Route path="/cashier/:id" element={<Checkout />} />
                            </Route>
                            */}
                            {(isAuthenticated) ? (
                                <>
                                    <Route path="/" element={<PrivateRoute/>}>
                                        <Route path="/" element={<Navigate to="/dashboard" />} />
                                        <Route path="/auth/mfa" element={<MFA />} />
                                        <Route path="/dashboard" element={<HomeV2 />} />
                                        <Route path="/financial-statement" element={<Statement />} />
                                        <Route path="/payments" element={<Payments />} />
                                        <Route path="/pix/transfer" element={<PixTransfer />} />
                                        <Route path="/withdrawal" element={<Withdrawal />} />
                                        <Route path="/withdrawal/:id" element={<WithdrawalDetail />} />
                                        <Route path="/deposit" element={<Deposit />} />
                                        <Route path="/crypto" element={<Crypto />} />
                                        <Route path="/easycard" element={<Easycard />} />

                                        <Route path="/integrations/app" element={<IntegrationsApp />} />
                                        <Route path="/integrations/security-token" element={<SecurityToken />} />

                                        <Route path="/settings/create-multi-factor" element={<CreateMultiFactor />} />
                                        <Route path="/settings/security-password" element={<SecurityPassword />} />

                                        <Route path="/user-settings" element={<UserSetting />} />
                                        <Route path="/user-settings/reset-password" element={<ChangePassword />} />
                                    </Route>
                                    <Route path="*" element={<Navigate to="/" />} />
                                </>
                            ) : (
                                <Route path="/" element={<PublicRoute/>}>
                                    <Route path="/" element={<Navigate to="/auth/login" />} />
                                    <Route path="/auth/login" element={<Login />} />
                                    <Route path="/auth/register" element={<Register />} />
                                    <Route path="/password/recovery" element={<Recovery />} />
                                    <Route path="*" element={<Navigate to="/" />} />
                                </Route>
                            )}
                        </Routes>
                    </Suspense>
                    <ToastContainer 
                        position="bottom-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="dark"
                    />
                </BrowserRouter>
            </ProvideAuth>
        </QueryClientProvider>
    );
}

export default App;
