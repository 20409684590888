import React, { useState, useEffect, forwardRef } from "react";
import { useMutation } from "react-query";
import axios from "axios";
// import uploadService from "./uploadService";
// import helpers from "./helpers";

import { styled } from '@mui/material/styles';
import { Controller, useFormContext, useForm, get } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';

import Button from '@/components/Button';
import Modal, { ModalBody, ModalFooter } from '@/components/Modal';

import API from "@/utility/axios";

const cdnURL = import.meta.env.VITE_CDN_URL;

const uploadService = {
    async uploadImage(url, file) {
        const response = await axios.put(url, file, {
            headers: {
                "Content-Type": file.type,
                // "Authorization": 
            }
        });
        return response.data;
    },
  
    async getSignedUrl(data) {
        const response = await API.post(`/upload/signed-url`, data);
        return response.data;
    },
};

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const BoxFile = styled(Box)({
    position: 'relative',
    display: 'flex',
    width: '100%',
    // height: '100%',
    flexDirection: 'column',
    gap: '.5rem',
    borderRadius: 'calc(0.3rem - 2px)',
    borderWidth: '1px',
    borderColor: '#9b9b9b',
    borderStyle: 'dashed',
    backgroundColor: 'var(--template-palette-background-default)',
    padding: '.5rem',
    '.file-content': {
        display: 'flex',
        width: '100%',
        height: '7rem',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center'
    },
    '.file-preview': {
        padding: '.5rem',
        // backgroundColor: '#333',
        borderWidth: '1px',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'relative',
        'img': {
            maxWidth: '230px',
            objectFit: 'contain',
            color: 'transparent'
        }
    },
    '.file-typography': {
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '.5rem',
        color: '#9b9b9b',
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: '.75rem',
        lineHeight: '1rem',
        textAlign: 'center',
        paddingLeft: '.5rem',
        paddingRight: '.5rem',
        textWrap: 'wrap'
    }
});

const FileUpload = forwardRef(({ field, onSuccess, onError, defaultImage }, ref) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(defaultImage || "");
    const [isModalOpen, setModalOpen] = useState(false);

    const { mutate: uploadImage, isLoading: isUploading } = useMutation(
        (fileData) => uploadService.uploadImage(fileData.url, fileData.file),
        {
            onSuccess: (data) => {
                const { url } = data;
                onSuccess && onSuccess(url);
                field.onChange(url.replace(`s3.us-east-1.amazonaws.com/${cdnURL}`, cdnURL));
                console.log("Upload realizado com sucesso!");
            },
            onError: (error) => {
                console.error(error);
                onError && onError("Erro ao realizar upload.");
            },
        }
    );

    const { mutate: getSignedUrl, isLoading: isGettingSignedUrl } = useMutation(
        (data) => uploadService.getSignedUrl(data),
        {
            onSuccess: ({ data }) => {
                uploadImage({ file: selectedFile, url: data.url });
            },
            onError: (error) => {
                console.error(error);
                onError && onError("Erro ao obter URL assinada.");
            },
        }
    );

    useEffect(() => {
        if (defaultImage) {
            setPreview(defaultImage);
        }
    }, [defaultImage]);

    const handleFileChange = (event) => {
        const file = event.target.files?.[0];
        if (file) {
            setSelectedFile(file);
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target?.result) setPreview(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUpload = () => {
        if (!selectedFile) {
            alert("Selecione uma imagem para fazer o upload.");
            return;
        }
        getSignedUrl({
            filename: selectedFile.name,
            contentType: selectedFile.type,
            folder: 'logoBusiness'
        });
    };

    const handleRemove = () => {
        setSelectedFile(null);
        setPreview("");
        field.onChange("");
    };
    
    return (
        <Box ref={ref}>
            <Box sx={{ position: 'relative' }}>
                <VisuallyHiddenInput
                    type="file"
                    id={field.name}
                    accept="image/png, image/jpeg, image/ico"
                    onChange={handleFileChange}
                />
            </Box>
            <BoxFile>
                <Box className="file-content">
                    {preview ? (
                        <div className="file-preview">
                            <img alt={field.name} width="60" height="60" decoding="async" data-nimg="1" src={preview} style={{ color: 'transparent' }} />
                        </div>
                    ) : (
                        <p className="file-typography" htmlhtmlFor={field.name} onClick={() => setModalOpen(true)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-circle-plus size-6">
                                <circle cx="12" cy="12" r="10"></circle>
                                <path d="M8 12h8"></path>
                                <path d="M12 8v8"></path>
                            </svg>
                            Adicione uma imagem
                        </p>
                    )}
                </Box>
                {preview && (
                    <Button className="custom" variant="outlined" type="button" onClick={handleRemove} sx={{ margin: '0 !important' }}
                    startAdornment={
                        <InputAdornment position="start" sx={{ color: 'text.primary' }}>
                                <DeleteOutlineRoundedIcon />
                        </InputAdornment>
                    }>
                        Remover
                    </Button>
                )}
            </BoxFile>
            <Typography variant="subtitle1" component="p" sx={{ fontSize: '.75rem', lineHeight: '1rem', marginTop: '.5rem' }}>
                Tamanho sugerido: 150 px x 150 px
            </Typography>
            <Modal
                open={isModalOpen}
                close={() => setModalOpen(false)}
            >
                <ModalBody>
                    <div className="flex flex-col space-y-2 text-center sm:text-left">
                        <h2 id="radix-:r1i:" className="text-lg font-semibold">Faça upload da sua imagem</h2>
                    </div>
                    <div className="relative my-2 flex h-[420px] w-full cursor-pointer items-center justify-center rounded-md border border-dashed border-muted-foreground">
                        {preview ? (
                            <div className="flex flex-col gap-2">
                                <img src={preview} alt={field.name} className="w-fit max-w-[230px] rounded-md border object-cover p-3"/>
                                <Button data-state="closed" sx={{ position: 'absolute', top: '1rem', right: '1rem' }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash size-4">
                                        <path d="M3 6h18"></path>
                                        <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                                        <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                                    </svg>
                                </Button>
                            </div>
                        ) : (
                            <div className="flex flex-col items-center">
                                <label className="w-fit cursor-pointer rounded-md border border-info bg-info-opacity px-4 py-2 text-xs font-semibold uppercase text-foreground" htmlFor={field.name}>Buscar imagem</label>
                                <span className="mt-2 w-[242px] text-center text-xs text-muted-foreground">Aceitamos os formatos .jpg e .png com menos de 500kb</span>
                            </div>
                        )}                        
                    </div>
                    <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2 gap-2">
                        <Button className="custom" color="primary" type="button" sx={{ margin: '0 !important' }}>Cancelar</Button>
                        <Button className="custom" color="success" sx={{ margin: '0 !important' }} onClick={handleUpload} disabled={isUploading || isGettingSignedUrl}>Salvar</Button>
                    </div>
                </ModalBody>
            </Modal>
        </Box>
    )
});

const InputFile = forwardRef(({
    name,
    rules = {},
    ...props
}, ref) => {
    const { control, formState } = useFormContext();
    const error = get(formState.errors, name);

    return (
        <>
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field }) => {
                    return (
                        <FileUpload
                            ref={ref}
                            error={!!error?.message}
                            field={field}
                            {...props}
                        />
                    )
                }}
            />
            {error?.message && (
                <FormHelperText sx={{ mx: 'unset', ml: '3px', color: '#dc3545' }}>
                    {error?.message}
                </FormHelperText>
            )}
        </>
    );
});

export default InputFile;