

import React from "react";
import { useMediaQuery } from 'react-responsive';
import { Helmet } from 'react-helmet-async';

import CssBaseline from '@mui/material/CssBaseline';
import AppTheme from '@/theme/AppTheme';

import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from '@/theme/customizations';

const xThemeComponents = {
    ...chartsCustomizations,
    ...dataGridCustomizations,
    ...datePickersCustomizations,
    ...treeViewCustomizations,
};

const Layout = ({ children, ...props }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

    return (
        <AppTheme {...props} themeComponents={xThemeComponents}>
            <CssBaseline enableColorScheme />
            <Helmet>
                <title>{ import.meta.env.VITE_APP_NAME } - A plataforma de pagamentos mais rápida e completa do mercado</title>
                {/* <link rel="icon" type="image/png" href={logo} /> */}
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            </Helmet>
            { children }
        </AppTheme>
    )
}

export default Layout;