import * as React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import Link from '@mui/material/Link';

const StyledBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  margin: theme.spacing(1, 0),
  [`& .${breadcrumbsClasses.separator}`]: {
    color: (theme.vars || theme).palette.action.disabled,
    margin: 1,
  },
  [`& .${breadcrumbsClasses.ol}`]: {
    alignItems: 'center',
  },
}));

export default function NavbarBreadcrumbs() {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <StyledBreadcrumbs
            aria-label="breadcrumb"
            separator={<NavigateNextRoundedIcon fontSize="small" />}
        >
            <Typography variant="body1">Dashboard</Typography>
            {pathnames.map((value, index) => {
                if (value == 'dashboard') {
                    return (
                        <Typography key={index} variant="body1" sx={{ color: 'text.primary', fontWeight: 600 }}>
                            Overview
                        </Typography>
                    )
                }

                const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                const isLast = index === pathnames.length - 1;

                const capitalize = (string) => {
                    return string.charAt(0).toUpperCase() + string.slice(1);
                };

                return isLast ? (
                    <Typography variant="body1" sx={{ color: 'text.primary', fontWeight: 600 }} key={index}>
                        { capitalize(value) }
                    </Typography>
                ) : (
                    <Typography variant="body1" key={index}>
                        { capitalize(value) }
                    </Typography>
                )
            })}
        </StyledBreadcrumbs>
    );
}
