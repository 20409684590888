import * as React from 'react';
import Button, { buttonClasses } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const CustomizedDataGrid = styled(({ ...props }) => (
    <Button
        variant="contained"
        size="small"
        color="primary"
        {...props}
    />
))({
    border: 'unset',
    backgroundImage: 'unset',
    color: 'var(--white)',
    transition: '.5s',
    boxShadow: '0 0 0 .25rem rgba(var(--primary),.3)',
    [`&.${buttonClasses.colorPrimary}`]: {
        backgroundColor: 'var(--primary)',
    },
    [`&.${buttonClasses.colorPrimary}:hover`]: {
        backgroundColor: 'var(--primary-dark-10)'
    },
    [`&.${buttonClasses.colorPrimary}::active`]: {
        backgroundColor: 'var(--primary-dark-10)'
    },
    [`&.${buttonClasses.outlined}`]: {
        backgroundColor: 'transparent !important',
        border: '1px solid var(--primary) !important',
        color: 'var(--primary)',
        transition: '.5s'
    },
    [`&.${buttonClasses.outlined}:hover`]: {
        /*
        backgroundColor: 'var(--primary)',
        color: '#fcfcfc',
        transition: '.5s'
        */
    },
    [`&.${buttonClasses.disabled}`]: {
        cursor: 'pointer',
        opacity: .5,
        pointerEvents: 'none'
    },
    [`&.${buttonClasses.colorPrimary}.${buttonClasses.disabled}`]: {
        color: 'var(--white)',
    },
    [`&.${buttonClasses.colorSecondary}`]: {
        backgroundColor: 'var(--secondary)',
    },
    [`&.${buttonClasses.colorSecondary}:hover`]: {
        backgroundColor: 'var(--secondary-light-30)'
    },
    [`&.${buttonClasses.colorSecondary}::active`]: {
        backgroundColor: 'var(--secondary-light-30)'
    },
    [`&.custom`]: {
        alignItems: 'center',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        display: 'flex',
        fontSize: '14px',
        fontWeight: 600,
        height: '35px',
        justifyContent: 'center',
        letterSpacing: '1px',
        margin: '20px 0',
        opacity: .9,
        overflow: 'hidden',
        padding: '0 20px !important',
        position: 'relative',
        textAlign: 'center',
        textDecoration: 'none',
        transition: 'all .5s',
    },
    [`&.custom:disabled`]: {
        cursor: 'pointer',
        opacity: .5,
        pointerEvents: 'none'
    },
    [`span`]: {
        alignItems: 'center',
        display: 'flex',
        whiteSpace: 'nowrap'
    }
});

export default CustomizedDataGrid;