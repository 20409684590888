import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import * as Sentry from "@sentry/react";
import { I18nextProvider } from "react-i18next";
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { DarkModeContextProvider } from "./context/darkModeContext";
import App from "./App";
import i18n from "../i18n";
import { store, persistor } from "./store";

/*
Sentry.init({
  dsn: "https://c193090d3a7b16116b2652b6880d13de@o4505590138535936.ingest.sentry.io/4505625573654528",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", "localhost:5173", "http://dash.izipaynow.com", "http://dash-sandbox.izipaynow.com", "http://dash.rmpay.com.br"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
*/

const root = document.getElementById("root");

ReactDOM.createRoot(root).render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <HelmetProvider>
                        <App />
                    </HelmetProvider>
                </PersistGate>
            </Provider>
        </I18nextProvider>
    </React.StrictMode>
);