import { useState } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import MatIcon from '@/components/MatIcon';
import Button from '@/components/Button';

import CompleteRegistration from '@/modals/CompleteRegistration';

const Header = styled(Box)(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '1rem',
    paddingBottom: '1rem',
    backgroundColor: '#1e1e1e',
    color: '#fff',
    gap: '.5rem',
    'p': {
        fontSize: '.875rem',
        lineHeight: '1.25rem',
        fontWeight: 400,
        marginBottom: 0,
        'strong': {
            color: '#eaf5e9'
        }
    },
    'button': {
        marginLeft: '1rem !important'
    }
}));

const HeaderInfo = () => {
    const [ openModal, setModalOpen ] = useState(false);

    return (
        <>
            <Header sx={{ backgroundColor: '#121212' }}>
                <MatIcon sx={{ color: '#eaf5e9' }}>
                    <InfoOutlinedIcon />
                </MatIcon>
                <p className="text-muted-foreground/50 text-sm font-normal">
                    <strong className="text-foreground">Etapa 1 de 2: </strong>Complete o cadastro da sua empresa.
                </p>
                <Button className="custom" color="secondary" sx={{ margin: '0 !important' }} onClick={() => setModalOpen(true)}>
                    <span className="custom-button__wrapper">
                        <span>Finalizar cadastro</span>
                    </span>
                </Button>
            </Header>
            <CompleteRegistration
                title="Informações do negócio"
                description="Complete os dados cadastrais do seu negócio."
                open={openModal}
                close={() => setModalOpen(false)}
            />
        </>
    )
}

export default HeaderInfo;