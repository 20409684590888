import { forwardRef } from 'react';
import { Controller, useFormContext, get } from "react-hook-form";
import { styled } from '@mui/material/styles';
import TextField, { textFieldClasses } from '@mui/material/TextField';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import OutlinedInput, { outlinedInputClasses } from '@mui/material/OutlinedInput';

// import { CustomizedInput } from './Input';

const CustomizedAutocomplete = styled(Autocomplete)(({ theme }) => ({
    [`&.${autocompleteClasses.root}`]: {
        backgroundColor: 'var(--template-palette-background-default)'
    },
    [`&.${autocompleteClasses.focused}`]: {
        /*
        outline: 0,
        borderColor: 'var(--primary-light-30)!important',
        boxShadow: '0 0 0 .2rem var(--primary-light-60)!important',
        color: 'var(--bs-body-color)',
        */
    },
    [`&.${autocompleteClasses.hover}`]: {
        backgroundColor: 'unset',
        border: 'unset',
    },
    // MuiOutlinedInput
    [`.${outlinedInputClasses.root}`]: {
        // display: 'block',
        width: '100%',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#212529',
        appearance: 'none',
        backgroundColor: '#ffffff',
        backgroundClip: 'padding-box',
        borderRadius: '.375rem',
        transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    },
    [`.${outlinedInputClasses.focused}`]: {
        outline: 0,
        borderColor: 'var(--primary-light-30)!important',
        boxShadow: '0 0 0 .2rem var(--primary-light-60)!important',
        color: 'var(--bs-body-color)',
    },
    [`.${outlinedInputClasses.disabled}`]: {
        backgroundColor: '#e9ecef',
        opacity: 1,
        pointerEvents: 'none'
    }
}));

export const Input = styled(TextField)(({ theme }) => ({
    [`&.${outlinedInputClasses.root}`]: {
        // display: 'block',
        width: '100%',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#212529',
        appearance: 'none',
        backgroundColor: '#ffffff',
        backgroundClip: 'padding-box',
        borderRadius: '.375rem',
        transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    },
    [`&.${outlinedInputClasses.focused}`]: {
        outline: 0,
        borderColor: 'var(--primary-light-30)!important',
        boxShadow: '0 0 0 .2rem var(--primary-light-60)!important',
        color: 'var(--bs-body-color)',
    },
    [`&.${outlinedInputClasses.disabled}`]: {
        backgroundColor: '#e9ecef',
        opacity: 1
    }
}));

// Usando forwardRef para permitir que o componente receba refs
const SelectSearch = forwardRef(({
    name,
    placeholder = 'Selecione uma das opções',
    options = [],
    defaultValue = '',
    rules = {},
    onBlur,
    onChange,
    ...props
}, ref) => {
    const { control, formState } = useFormContext();
    const error = get(formState.errors, name);

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={rules}
            render={({ field }) => (
                <CustomizedAutocomplete
                    {...field}
                    value={field.value}
                    freeSolo
                    disableClearable
                    options={options.map((option) => option.label || option)}
                    getOptionLabel={(option) => {
                        // console.log('getOptionLabel', options, option);
                        return option.label || String(option)
                    }}
                    onChange={(event, newValue) => {
                        if (onChange) {
                            onChange(event, newValue)
                        }
                        return field.onChange(newValue)
                    }}
                    renderInput={(params) => 
                        <Input
                            {...params}
                            placeholder={placeholder}
                            slotProps={{
                                input: {
                                    ...params.InputProps,
                                    type: 'search',
                                }
                            }}
                        />}
                    ref={ref}
                    {...props}
                />
            )}
        />
    );
});

export default SelectSearch;