

import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useMediaQuery } from 'react-responsive';
import { Container } from "reactstrap";
import classNames from "classnames";
import { Helmet } from 'react-helmet-async';

import { DarkModeContext } from "../context/darkModeContext.jsx";
import Sidebar from "../components/admin/sidebar/Sidebar.jsx";
import Navbar from "../components/admin/navbar/Navbar.jsx";
import Content from "../components/admin/content/Content.jsx";

import { alpha } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import AppNavbar from '@/components/dashboard/AppNavbar';
import Header from '@/components/dashboard/Header';
import MainGrid from '@/components/dashboard/MainGrid';
import SideMenu from '@/components/dashboard/SideMenu';
import AppTheme from '@/theme/AppTheme';

import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from '@/theme/customizations';

const xThemeComponents = {
    ...chartsCustomizations,
    ...dataGridCustomizations,
    ...datePickersCustomizations,
    ...treeViewCustomizations,
};

const Layout = ({ children, ...props }) => {
    const isMobile = useMediaQuery({ query: '(max-width: 800px)' });

    return (
        <AppTheme {...props} themeComponents={xThemeComponents}>
            <CssBaseline enableColorScheme />
            <Helmet>
                <title>{ import.meta.env.VITE_APP_NAME } - A plataforma de pagamentos mais rápida e completa do mercado</title>
                {/* <link rel="icon" type="image/png" href={logo} /> */}
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            </Helmet>
            <Box sx={{ display: 'flex' }}>
                <Sidebar />
                <Navbar />
                {/* Main content */}
                <Box
                    component="main"
                    sx={(theme) => ({
                        flexGrow: 1,
                        /*
                        backgroundColor: theme.vars
                            ? `rgba(${theme.vars.palette.background.defaultChannel} / 1)`
                            : alpha(theme.palette.background.default, 1),
                        */
                        overflow: 'auto',
                        // mt: '80px'
                    })}
                >
                    <Stack
                        spacing={2}
                        sx={{
                            alignItems: 'center',
                            mx: 10,
                            ml: 20,
                            mt: { xs: 15, md: 8 },
                        }}
                    >
                        {/* <Header /> */}
                        { children }
                    </Stack>
                </Box>
            </Box>
        </AppTheme>
    )
}

export default Layout;