import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from "@/utility/axios";

export const loginUser = createAsyncThunk(
    'auth/loginUser',
    async (credentials, thunkAPI) => {
        try {
            const response = await API.post(`/auth/login`, credentials);
            return response.data?.data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const twoFactorUser = createAsyncThunk(
    'auth/validTwoFactor',
    async (credentials, thunkAPI) => {
        try {
            const response = await API.post(`/auth/two-factor`, credentials);
            return response.data?.data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error.response?.data);
        }
    }
);

export const checkUser = createAsyncThunk(
    'auth/checkUser',
    async (credentials, thunkAPI) => {
        try {
            const response = await API.get(`/user/check`);
            return response.data?.data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error.response?.data?.error);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        user: null,
        token: localStorage.getItem('token') || null,
        isAuthenticated: !!localStorage.getItem('token'),
        validate: [],
        loading: false,
        error: null,
    },
    reducers: {
        logout: (state) => {
            state.user = null;
            state.token = null;
            state.isAuthenticated = false;
            state.validate = [];
            state.loading = false;
            state.error = null;
            // localStorage.removeItem('token');
            localStorage.clear();
            window.location.replace("/");
        },
        removeValidation: (state, payload) => {
            if (state.validate.includes(payload)) {
                let index = state.validate.find(v => v == payload);
                if (index) {
                    state.validate.splice(index, 1);
                }
            }
        }
    },
    extraReducers: (builder) => {
        builder
        // loginUser
        .addCase(loginUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(loginUser.fulfilled, (state, action) => {
            state.loading = false;
            // state.user = action.payload.user;
            state.token = action.payload.token;
            state.isAuthenticated = true;
            if (action.payload.requires2FA) {
                state.validate.push('2fa');
            }
            localStorage.setItem('token', action.payload.token);
        })
        .addCase(loginUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        })
        // twoFactorUser
        .addCase(twoFactorUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(twoFactorUser.fulfilled, (state, action) => {
            state.loading = false;
            // state.user = action.payload.user;
            state.token = action.payload.token;
            state.isAuthenticated = true;
            if (state.validate.includes('2fa')) {
                let index = state.validate.find(v => v == '2fa');
                if (index) {
                    state.validate.splice(index, 1);
                }
            }
            localStorage.setItem('token', action.payload.token);
        })
        .addCase(twoFactorUser.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload?.data?.err || action.payload?.msg || 'Ocorreu um erro inesperado. Tente novamente.';
            if (action.payload.code == 4004002) {
                state.user = null;
                state.token = null;
                state.isAuthenticated = false;
                state.validate = [];
                state.loading = false;
                state.error = null;
                localStorage.removeItem('token');
            }
        })
        // checkUser
        .addCase(checkUser.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(checkUser.fulfilled, (state, action) => {
            state.loading = false;
            state.user = action.payload;
            state.isAuthenticated = true;
        })
        .addCase(checkUser.rejected, (state, action) => {
            state.user = null;
            state.token = null;
            state.isAuthenticated = false;
            state.validate = [];
            state.loading = false;
            state.error = null;            
            localStorage.removeItem('token');
        });
    },
});

export const { logout, removeValidation } = authSlice.actions;

export default authSlice.reducer;
