import { forwardRef } from 'react';
import { Controller, useFormContext, get } from "react-hook-form";

import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select, { selectClasses } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

const CustomizedSelect = styled(Select)(({ theme }) => ({
    color: '#212529',
    backgroundColor: 'unset',
    border: '1px solid var(--template-palette-divider)',
    boxShadow: 'unset',
    padding: '.375rem .75rem',
    fontSize: '1rem',
    fontWeight: '400',
    lineHeight: '1.5',
    
    [`&.${selectClasses.root}`]: {
        backgroundColor: 'var(--template-palette-background-default)',
    },
    [`&.${selectClasses.focused}`]: {
        outline: 0,
        borderColor: 'var(--primary-light-30)!important',
        boxShadow: '0 0 0 .2rem var(--primary-light-60)!important',
        color: 'var(--bs-body-color)',
    },
    [`&.${selectClasses.hover}`]: {
        backgroundColor: 'unset',
        border: 'unset',
    }
}));

// Usando forwardRef para permitir que o componente receba refs
const SelectSmall = forwardRef(({ name, placeholder = 'Selecione uma das opções', options = [], defaultValue = '', rules = {}, ...props }, ref) => {
    const { control, formState } = useFormContext();
    const error = get(formState.errors, name);

    // console.log('Select', name, formState.errors);

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field }) => (
                    <CustomizedSelect
                        {...field}
                        error={!!error?.message}
                        displayEmpty
                        value={field.value}
                        onChange={(event) => field.onChange(event.target.value)}
                        renderValue={(selected) => {
                            if (selected == null || selected.length === 0) {
                                return placeholder;
                            }
                            return options.find(o => o.value == selected)?.label;
                        }}
                        ref={ref}
                        {...props}
                    >
                        <MenuItem value="">
                            <em>{ placeholder }</em>
                        </MenuItem>
                        {options.map((option, index) => (
                            <MenuItem value={option.value} key={index}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </CustomizedSelect>
                )}
            />
            {error?.message && (
                <FormHelperText sx={{ mx: 'unset', ml: '3px', color: '#dc3545' }}>
                    {error?.message}
                </FormHelperText>
            )}
        </>
    );
});

export default SelectSmall;