import { forwardRef, useState } from 'react';
import classNames from 'classnames';

import { styled } from '@mui/material/styles';
import { Controller, useFormContext, useForm, get } from "react-hook-form";
import FormHelperText from '@mui/material/FormHelperText';
import Box from '@mui/material/Box';

import OutlinedInput, { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { BsExclamationCircle } from "react-icons/bs";
import MatIcon from '@/components/MatIcon';
import HideImage from '@/assets/icons/hide.svg?react';
import ShowImage from '@/assets/icons/show.svg?react';
import InvalidImage from '@/assets/icons/is-invalid.svg?react';

export const CustomizedInput = styled(OutlinedInput)(({ theme }) => ({
    [`&.${outlinedInputClasses.root}`]: {
        // display: 'block',
        width: '100%',
        padding: '.375rem .75rem',
        fontSize: '1rem',
        fontWeight: '400',
        lineHeight: '1.5',
        color: '#212529',
        appearance: 'none',
        backgroundColor: '#ffffff',
        backgroundClip: 'padding-box',
        borderRadius: '.375rem',
        transition: 'border-color .15s ease-in-out, box-shadow .15s ease-in-out',
    },
    [`&.${outlinedInputClasses.focused}`]: {
        outline: 0,
        borderColor: 'var(--primary-light-30)!important',
        boxShadow: '0 0 0 .2rem var(--primary-light-60)!important',
        color: 'var(--bs-body-color)',
    },
    [`&.${outlinedInputClasses.disabled}`]: {
        backgroundColor: '#e9ecef',
        opacity: 1
    }
}));

const InputSmall = forwardRef(({
    name,
    type = 'text',
    password = false,
    defaultValue = '',
    rules = {},
    ...props
}, ref) => {
    const { control, formState } = useFormContext();
    const error = get(formState.errors, name);
    
    const [ show, setShow ] = useState(false);

    // console.log('Input', name, formState.errors);

    return (
        <>
            <Controller
                name={name}
                control={control}
                defaultValue={defaultValue}
                rules={rules}
                render={({ field }) => {
                    return (
                        <CustomizedInput
                            {...field}
                            ref={ref}
                            error={!!error?.message}
                            value={field.value|| ""}
                            onChange={(event) => field.onChange(event.target.value)}
                            type={password && show ? 'text' : password && !show ? 'password' : type}
                            {...props}
                            endAdornment={
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    {props.endAdornment}
                                    {password && (
                                        <MatIcon
                                            role="img"
                                            className={classNames({
                                                'mat-icon': true,
                                                'mat-icon-no-color': true,
                                                'text-danger': error?.message
                                            })}
                                            aria-hidden="true"
                                            data-mat-icon-type="svg"
                                            data-mat-icon-name="check-circle"
                                            sx={{ color: '#99a1b7', height: '25px', width: '20px', cursor: 'pointer' }}
                                        >
                                            { show ? (
                                                <ShowImage width="100%" height="100%" fill="currentColor" onClick={() => setShow(false)} />
                                             ) : (
                                                <HideImage width="100%" height="100%" fill="currentColor" onClick={() => setShow(true)} />
                                            )}
                                        </MatIcon>
                                    )}
                                    {error?.message && (
                                        <MatIcon
                                            role="img"
                                            className={classNames({
                                                'mat-icon': true,
                                                'mat-icon-no-color': true,
                                                'text-danger': error?.message
                                            })}
                                            aria-hidden="true"
                                            data-mat-icon-type="svg"
                                            data-mat-icon-name="bs-exclamation-circle"
                                            sx={{ color: '#99a1b7', height: '25px', width: '20px', cursor: 'pointer' }}
                                        >
                                            <InvalidImage width="100%" height="100%" />
                                        </MatIcon>
                                    )}
                                </Box>
                            }
                        />
                    )
                }}
            />
            {error?.message && (
                <FormHelperText sx={{ mx: 'unset', ml: '3px', color: '#dc3545' }}>
                    {error?.message}
                </FormHelperText>
            )}
        </>
    );
});

export default InputSmall;