import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from "@/utility/axios";

export const getAppList = createAsyncThunk(
    'app/getAll',
    async (_, thunkAPI) => {
        try {
            const response = await API.get(`/applications`);
            return response.data?.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response?.data?.error);
        }
    }
);

const applicationSlice = createSlice({
    name: 'application',
    initialState: {
        loading: false,
        error: null,
        data: []
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(getAppList.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getAppList.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        })
        .addCase(getAppList.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

// export const { logout } = authSlice.actions;

export default applicationSlice.reducer;
