import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import API from "@/utility/axios";

export const fetchStatement = createAsyncThunk(
    'cashout/fetchStatement',
    async (_, thunkAPI) => {
        try {
            const response = await API.get(`/cashout/statement`);
            console.log(response);
            return response.data?.data;
        } catch (error) {
            console.log(error);
            return thunkAPI.rejectWithValue(error.response?.data?.error);
        }
    }
);

const cashoutSlice = createSlice({
    name: 'cashout',
    initialState: {
        loading: false,
        error: null,
        data: []
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
        .addCase(fetchStatement.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(fetchStatement.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload;
        })
        .addCase(fetchStatement.rejected, (state, action) => {
            state.loading = false;
            state.error = action.payload;
        });
    },
});

export default cashoutSlice.reducer;