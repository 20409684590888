import { useState } from 'react';
import { useQuery } from "react-query";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-toastify';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid2';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import OutlinedInput from "@/components/Input";
import InputMask from "@/components/InputMask";
import Select from "@/components/Select";
import SelectSearch from "@/components/SelectSearch";
import Button from '@/components/Button';
import Progress from '@/components/Progress';
import MatIcon from '@/components/MatIcon';
import Tooltip from "@/components/Tooltip";
import FileUpload from "@/components/FileUpload";
import { DialogModal, ModalBody } from "@/components/Modal";

import API from "@/utility/axios";
import classNames from 'classnames';

const Form = styled('form')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%'
}));

const StepTitle = styled('span')(({ theme }) => ({
    flex: '1 1 0%',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
    textAlign: 'center',
    fontWeight: 600,
    cursor: 'pointer',
    '&.active': {
        backgroundColor: 'var(--secondary)',
        borderRadius: 'calc(0.3rem - 2px)',
        color: '#fff',
    }
}));

const resolverSchema = yup.object().shape({
    store: yup.object().shape({
        name: yup.string().required("O nome da loja é obrigatório."),
        operations: yup.string().required("O campo de operações é obrigatório."),
        logo: yup.string().url("O logo deve ser uma URL válida.").nullable().optional()
    }),
    address: yup.object().shape({
        state: yup.string().required("O estado é obrigatório."),
        city: yup.string().required("A cidade é obrigatória."),
        postalCode: yup.string()
            .matches(/^\d{5}-\d{3}$/, "O CEP deve estar no formato 12345-678.")
            .required("O CEP é obrigatório."),
        address: yup.string().required("O endereço é obrigatório."),
        neighborhood: yup.string().required("O bairro é obrigatório."),
        complement: yup.string().nullable(),
        number: yup.string().required("O número é obrigatório.")
    }),
    identification: yup.object().shape({
        accountType: yup.string().required("O tipo de conta é obrigatório."),
        administrator: yup.object().shape({
            name: yup.string().required("O nome do administrador é obrigatório."),
            email: yup.string()
                .email("O email deve ser válido.")
                .required("O email é obrigatório."),
            documentNumber: yup.string()
                .matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, "O CPF deve estar no formato 999.999.999-99.")
                .required("O número do documento é obrigatório."),
            phone: yup.object().shape({
                number: yup.string()
                    .matches(/^\(\d{2}\)\s\d{4,5}-\d{4}$/, "O telefone deve estar no formato (99) 99999-9999.")
                    .required("O telefone é obrigatório."),
                countryCode: yup.string()
                    .matches(/^\+\d{1,3}$/, "O código do país deve estar no formato +55.")
                    .required("O código do país é obrigatório.")
            })
        }),
        company: yup.object().shape({
            companyName: yup.string()
                .nullable()
                .optional()
                .when("accountType", {
                    is: "legal",
                    then: yup.string()
                        .required("O nome da empresa é obrigatório."),
                    //otherwise: yup.string().nullable()
                }),
            documentNumber: yup.string()
                .nullable()
                .optional()
                .when("accountType", {
                    is: "legal",
                    then: yup.string()
                        .matches(/^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/, "O CNPJ deve estar no formato 99.999.999/9999-99.")
                        .required("O número do documento da empresa é obrigatório."),
                    //otherwise: yup.string().nullable()
                }),
        }),
    }),
});

/*
const getResolver = (activeStep) => {
    const schema = validationSchemas[activeStep] || yup.object().shape({});
    return yupResolver(schema);
};
*/

const Identification = ({ methods }) => {
    const { register, watch } = methods;

    const personType = watch('identification.accountType');

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.75rem' }}>
            <FormControl sx={{ width: '100%' }}>
                <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                    Tipo conta
                </Typography>
                <Select
                    name="identification.accountType"
                    placeholder="Selecione o tipo de conta"
                    options={[
                        { value: 'physical', label: 'Pessoa Física', documentType: 'CPF' },
                        { value: 'legal', label: 'Pessoa Jurídica', documentType: 'CNPJ' }
                    ]}
                    sx={{ flexGrow: 1 }}
                    {...register("identification.accountType")}
                />
            </FormControl>
            <Typography component="p" variant="subtitle2" sx={{ my: '.5rem' }}>
                Dados do administrador
            </Typography>
            <FormControl sx={{ width: '100%' }}>
                <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                    Nome completo
                </Typography>
                <OutlinedInput
                    name="identification.administrator.name"
                    placeholder="Digite o nome completo"
                    {...register("identification.administrator.name")}
                />
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
                <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                    E-mail
                </Typography>
                <OutlinedInput
                    name="identification.administrator.email"
                    placeholder="exemplo@exemplo.com"
                    {...register("identification.administrator.email")}
                />
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
                <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                    CPF
                </Typography>
                <InputMask
                    name="identification.administrator.documentNumber"
                    placeholder="999.999.999-99"
                    mask="999.999.999-99"
                    {...register("identification.administrator.documentNumber")}
                />
            </FormControl>
            <Box sx={{ display: 'flex', width: '100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'relative' }}>
                    <Box sx={{ display: 'flex', gap: '.75rem', width: '100%' }}>
                        <FormControl sx={{ width: '102px' }}>
                            <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                                DDI
                            </Typography>
                            <Select
                                name="identification.administrator.phone.countryCode"
                                options={[
                                    { value: '+55', label: '+55', label2: '+55 - Brazil' }
                                ]}
                                sx={{ flexGrow: 1 }}
                                {...register("identification.administrator.phone.countryCode")}
                            />
                        </FormControl>
                        <FormControl sx={{ width: '100%' }}>
                            <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                                Telefone
                            </Typography>
                            <InputMask
                                name="identification.administrator.phone.number"
                                placeholder="Telefone"
                                mask="(99) 99999-9999"
                                {...register("identification.administrator.phone.number")}
                            />
                        </FormControl>
                    </Box>
                </Box>
            </Box>
            {personType == 'legal' && (
                <>
                    <Typography component="p" variant="subtitle2" sx={{ my: '.5rem' }}>
                        Dados da empresa
                    </Typography>
                    <FormControl sx={{ width: '100%' }}>
                        <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                            Razão social
                        </Typography>
                        <OutlinedInput
                            name="identification.company.companyName"
                            placeholder="Digite a razão social"
                            {...register("identification.company.companyName")}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%' }}>
                        <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                            CNPJ
                        </Typography>
                        <InputMask
                            name="identification.company.documentNumber"
                            placeholder="99.999.999/9999-99"
                            mask="99.999.999/9999-99"
                            {...register("identification.company.documentNumber")}
                        />
                    </FormControl>
                </>
            )}
        </Box>
    )
}

const Store = ({ methods }) => {
    const { register, watch } = methods;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.75rem' }}>
            <FormControl sx={{ width: '100%' }}>
                <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                    Nome da loja
                </Typography>
                <OutlinedInput
                    name="store.name"
                    placeholder="Digite o nome completo"
                    {...register("store.name")}
                />
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
                <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                    Operações da loja
                </Typography>
                <Select
                    name="store.operations"
                    placeholder="Selecione as operações da sua loja"
                    options={[
                        { value: 'digital', label: 'Produtos Digitais' },
                        { value: 'physical', label: 'Produtos Físicos' }
                    ]}
                    sx={{ flexGrow: 1 }}
                    {...register("store.operations")}
                />
            </FormControl>
            <FormControl sx={{ width: '100%' }}>
                <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                    Operações da loja
                </Typography>
                <FileUpload
                    name="store.logo"
                    {...register("store.logo")}
                />
            </FormControl>
        </Box>
    )
}

const Address = ({ methods }) => {
    const { register, watch, setValue } = methods;
    const [ fetchZipcode, setFetchZipcode ] = useState(false);

    const { isLoading: loadingCities, error: errorCities, data: dataCities } = useQuery(["addressData"], async () => {
        try {
            const response = await API.get(`/address/cities`);
            return response.data?.data;
        } catch (error) {
            return error.response?.data?.data?.err || error.response?.data?.msg;
        }
    }, {
        refetchOnWindowFocus: false
    });

    const state = watch('address.state');

    const states = loadingCities ? [] : Array.from(new Map(dataCities.map(item => [item.code, { code: item.code, state: item.state }])).values())
        .map(option => ({ label: option.state }));

    const cities = loadingCities ? [] : dataCities.filter(c => state == c.state)
        .map(option => ({ label: option.city }));

    const onBlurZipcode = async (zipcode) => {
        try {
            zipcode = zipcode.replace(/[^0-9]+/g, '');

            if (zipcode.length != 8) return;
            setFetchZipcode(true);

            const { data } = await API.get(`/address/zipcode/${zipcode}`);
            if (data) {
                setValue('address.address', data?.data?.street);
                setValue('address.neighborhood', data?.data?.neighborhood);
                setValue('address.state', data?.data?.state);
                setValue('address.city', data?.data?.city);
                setFetchZipcode(false);
            }
        } catch (error) {
            console.log(error);
            setFetchZipcode(false);
        }
    }

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '.75rem' }}>
            <Box className="grid grid-cols-2 gap-3">
                <FormControl sx={{ width: '100%' }}>
                    <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                        CEP
                    </Typography>
                    <InputMask
                        tabIndex={0}
                        name="address.postalCode"
                        placeholder="Digite o CEP"
                        mask="99999-999"
                        {...register("address.postalCode")}
                        onBlur={(e) => onBlurZipcode(e.target.value)}
                    />
                </FormControl>
            </Box>
            <Box className="flex gap-3">
                <FormControl sx={{ width: '100%' }}>
                    <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                        Endereço
                    </Typography>
                    <OutlinedInput
                        name="address.address"
                        placeholder="Digite seu endereço"
                        disabled={fetchZipcode}
                        {...register("address.address")}
                    />
                </FormControl>
                <FormControl sx={{ width: '112px' }}>
                    <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                        Número
                    </Typography>
                    <OutlinedInput
                        tabIndex={1}
                        name="address.number"
                        placeholder="EX: 312"
                        {...register("address.number")}
                    />
                </FormControl>
            </Box>
            <Box className="grid grid-cols-2 gap-3">
                <FormControl sx={{ width: '100%' }}>
                    <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                        Complemento
                    </Typography>
                    <OutlinedInput
                        name="address.complement"
                        placeholder="Complemento"
                        disabled={fetchZipcode}
                        {...register("address.complement")}
                    />
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                    <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                        Bairro
                    </Typography>
                    <OutlinedInput
                        name="address.neighborhood"
                        placeholder="EX: Centro"
                        disabled={fetchZipcode}
                        {...register("address.neighborhood")}
                    />
                </FormControl>
            </Box>
            <Box className="grid grid-cols-2 gap-3">
                <FormControl sx={{ width: '100%' }}>
                    <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                        Cidade
                    </Typography>
                    <SelectSearch
                        disabled={fetchZipcode}
                        name="address.city"
                        placeholder="EX: São Paulo"
                        options={cities}
                        sx={{ flexGrow: 1 }}                                            
                        {...register("address.city")}
                    />
                </FormControl>
                <FormControl sx={{ width: '100%' }}>
                    <Typography component="label" variant="subtitle2" sx={{ mb: '4px', fontWeight: 600 }}>
                        Estado
                    </Typography>
                    <SelectSearch
                        disabled={fetchZipcode}
                        name="address.state"
                        placeholder="Selecione o estado"
                        options={states}
                        sx={{ flexGrow: 1 }}
                        {...register("address.state")}
                        onChange={() => {
                            setValue('address.city', '');
                        }}
                    />
                </FormControl>
            </Box>
        </Box>
    )
}

const StepComponent = (step, methods) => {
    switch (step) {
        case 'identification':
            return <Identification methods={methods} />
        break;
    
        case 'store':
            return <Store methods={methods} />
        break;

        case 'address':
            return <Address methods={methods} />
        break;

        default:
            return null;
        break;
    }
}

const RegistrationModal = ({ title, description, content, open, close, callback = () => {} }) => {
    const [ isLoading, setIsLoading ] = useState(false);
    const [ activeStep, setActiveStep ] = useState('identification');

    const steps = [
        {
            name: 'store',
            label: 'Loja'
        },
        {
            name: 'address',
            label: 'Endereço'
        },
        {
            name: 'identification',
            label: 'Identificação'
        }
    ];

    const methods = useForm({
        shouldUnregister: false,
        defaultValues: {
            store: {
                name: "Octo Club",
                operations: "digital",
                logo: ""
            },
            address: {
                state: "São Paulo",
                city: "Barretos",
                postalCode: "14783-101",
                address: "Avenida Sacadura Cabral",
                neighborhood: "Aeroporto",
                complement: "",
                number: "2547"
            },
            identification: {
                accountType: "physical",
                administrator: {
                    name: "MATHEUS DOS SANTOS RAMOS",
                    email: "br.aztec.dev@gmail.com",
                    documentNumber: "469.653.228-37",
                    phone: {
                        number: "(17) 98146-0439",
                        countryCode: "+55"
                    }
                },
                company: {
                    companyName: "",
                    documentNumber: ""
                }
            }
        },
        resolver: yupResolver(resolverSchema),
        mode: "onBlur"
    });
    const { handleSubmit, reset, trigger, formState: { errors }, setValue, register, watch } = methods;

    const onSubmit = async (payload) => {
        console.log(payload);
        /*
        try {
            console.log(payload);
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
        */
    }

    const orderStep = ['identification', 'store', 'address'];

    const isFirstStep = () => {
        return activeStep === orderStep[0];
    };

    const isLastStep = () => {
        return activeStep === orderStep[orderStep.length - 1];
    };

    const handleNext = async () => {
        const currentStepFields = Object.keys(
            resolverSchema.fields[activeStep]?.fields || {}
        ).map(field => `${activeStep}.${field}`);
    
        const isStepValid = await trigger(currentStepFields);
        if (!isStepValid) {
            console.log("Validation failed:", errors);
            return;
        }

        const currentStepIndex = orderStep.indexOf(activeStep);
        const nextStep = orderStep[currentStepIndex + 1];
        if (nextStep) {
            setActiveStep(nextStep);
        } else {
            console.log('Finish!');
        }
    };
  
    const handleBack = () => {
        const currentStepIndex = orderStep.indexOf(activeStep);
        const prevStep = orderStep[currentStepIndex - 1];
        if (prevStep) {
            setActiveStep(prevStep);
        }
    };
  
    const handleReset = () => {
        setActiveStep(0);
        reset();
    };

    // console.log('errors', errors);

    return (
        <DialogModal
            title={title}
            description={description}
            open={open}
            close={() => {
                close();
                reset();
            }}
            width={460}
        >
            <Box sx={{ display: 'flex' }}>
                {steps
                    .slice()
                    .sort((a, b) => orderStep.indexOf(a.name) - orderStep.indexOf(b.name))
                    .map((item, index) => (
                        <StepTitle className={classNames({ 'active': item.name == activeStep })} key={index} onClick={async () => {
                            if (activeStep == item.name) return;

                            const currentStepFields = Object.keys(
                                resolverSchema.fields[item.name]?.fields || {}
                            ).map(field => `${item.name}.${field}`);
                        
                            const isStepValid = await trigger(currentStepFields);
                            if (!isStepValid) {
                                return;
                            }

                            setActiveStep(item.name);

                            /*
                            const itemStepIndex = orderStep.indexOf(item.name);
                            const currentStepIndex = orderStep.indexOf(activeStep);
                            console.log(itemStepIndex, currentStepIndex, currentStepIndex < itemStepIndex);
                            if (currentStepIndex > itemStepIndex) {
                                setActiveStep(item.name);
                            }
                            */
                        }}>
                            { item.label }
                        </StepTitle>
                    )
                )}
            </Box>
            <FormProvider {...methods}>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    { StepComponent(activeStep, methods) }
                    <Box sx={{ display: 'flex', gap: '.75rem', marginTop: '1.5rem', marginLeft: 'auto' }}>
                        {!isFirstStep() ? (
                            <Button className="custom" onClick={handleBack} sx={{ margin: '0 !important' }}>
                                <span className="custom-button__wrapper">
                                    <span>Voltar</span>
                                </span>
                            </Button>
                        ) : (
                            <Button className="custom" onClick={() => {
                                close();
                                reset();
                            }} sx={{ margin: '0 !important' }}>
                                <span className="custom-button__wrapper">
                                    <span>Cancelar</span>
                                </span>
                            </Button>
                        )}
                        {!isLastStep() ? (
                            <Button className="custom" color="secondary" onClick={handleNext} sx={{ margin: '0 !important' }}>
                                <span className="custom-button__wrapper">
                                    <span>Avançar</span>
                                </span>
                            </Button>
                        ) : (
                            <Button className="custom" color="secondary" type="submit" sx={{ margin: '0 !important' }}>
                                <span className="custom-button__wrapper">
                                    <span>Salvar</span>
                                </span>
                            </Button>
                        )}
                    </Box>
                </Form>
            </FormProvider>
        </DialogModal>
    )
}

export default RegistrationModal;