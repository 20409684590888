import React, { useContext, createContext, useState, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import axios from "axios";
import { useQuery } from "react-query";

import LoadingScreen from "../components/loader";
import UserLayout from '../layout/admin.jsx';
import PageLayout from '../layout/index.jsx';
import API from "../utility/axios";

const baseURL = import.meta.env.VITE_API_URL;

const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export function useAuth() {
    return useContext(authContext);
}

function useProvideAuth() {
    const [ user, setUser ] = useState(null);

    const [ isLoading, setLoading ] = useState(false);
    const [ isCheck, setCheck ] = useState(false);

    const signin = async (username, password) => {
        setLoading(true);

        const storedToken = localStorage.getItem("token");
        const storedTime = localStorage.getItem("tokenTime");

        if (
            storedToken &&
            storedTime &&
            new Date().getTime() - storedTime < 25 * 60 * 1000
        ) {
            setLoading(false);
            return storedToken;
        }

        try {
            const body = {
                email: username,
                password: password,
            };
          
            const response = await axios.post(`${baseURL}/auth/login`, body);
        
            if (response.status !== 200) {
                throw new Error("Request failed");
            }
            if (response.status !== 403) {
                console.log(response);
            }

            setUser(response.data);

            // Save token and current time in local storage
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("tokenTime", new Date().getTime());
            localStorage.setItem("last_login", Date.now());

            setLoading(false);
            return response.data.token;

        } catch (error) {
            console.error(error);
            /*
            setLoading(false);
            localStorage.clear();
            if (error.response) {
                console.log("Response Status:", error.response.status); // This will log 403
                console.log("Response Data:", error.response.data);
            } else {
                console.error("Error:", error.message);
            }
            throw new Error("Something went wrong");
            */
        }
    };

    const signout = cb => {
        try {
            localStorage.clear();
            window.location.replace("/");
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const check = async () => {
        setCheck(true);

        try {
            const token = localStorage.getItem("token");
            if (!token) {
                throw new Error("Token empty");
            }

            const response = await axios.get(`${baseURL}/user/check`, {
                headers: {
                  Authorization: token
                }
            });
        
            if (response.status !== 200) {
                throw new Error("Request failed");
            }
            
            if (!user) {
                setUser(response.data);
                console.log('user', user);
            }
            
            setCheck(false);

            return user;
        } catch (error) {
            setCheck(false);
            localStorage.clear();
            /* window.location.reload(); */
            if (error.response) {
                console.log("Response Status:", error.response.status); // This will log 403
                console.log("Response Data:", error.response.data);
            } else {
                console.error("Error:", error.message);
            }
            throw new Error("Something went wrong");
        }
    }

    return {
        user,
        check,
        signin,
        signout,
        isLoading,
        setLoading,
        isCheck,
        setCheck
    };
}

import { useSelector } from 'react-redux';

const isLoading = () => {
    const { loading: loadingAuth } = useSelector((state) => state.auth);
    const { loading: loadingApp } = useSelector((state) => state.app);

    return (loadingAuth || loadingApp)
}

export function PrivateRoute({ children, ...rest }) {
    const location = useLocation();
    // const { user, check, isCheck } = useAuth();  
    // const token = localStorage.getItem("token");

    const loading = isLoading();
    const { isAuthenticated, validate } = useSelector((state) => state.auth);

    console.log('PrivateRoute -> validate:',validate,location.pathname);

    if (isAuthenticated && validate.includes('2fa') && location.pathname != '/auth/mfa') {
        return <Navigate to={"/auth/mfa"} />;

    } else if (location.pathname == '/auth/mfa') {
        return (
            <PageLayout background={false} rounded={false}>
                <LoadingScreen loading={loading} />
                <Outlet />
            </PageLayout>
        )
    }

    return isAuthenticated ? (
        <UserLayout background={false} rounded={false}>
            <LoadingScreen loading={loading} />
            <Outlet />
        </UserLayout>
    ) : (
        <Navigate to={"/"} />
    );
}

export function PublicRoute({ children, ...rest }) {
    const loading = isLoading();

    return (
        <PageLayout background={false} rounded={false}>
            <LoadingScreen loading={loading} />
            <Outlet />
        </PageLayout>
    );
}