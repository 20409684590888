import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchUserData } from './api';

// Ação assíncrona para buscar dados do usuário
export const fetchUser = createAsyncThunk(
    'user/fetchUser',
    async (userId, thunkAPI) => {
        const response = await fetchUserData(userId);  // Chamada da API
        return response.data;  // Retorna os dados como payload
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState: {
        data: null,
        status: 'idle',  // idle | loading | succeeded | failed
        error: null,
    },
    reducers: {
        // Adicione reducers síncronos se necessário
    },
    extraReducers: (builder) => {
        // Lidar com os diferentes estados da ação assíncrona
        builder
        .addCase(fetchUser.pending, (state) => {
            state.status = 'loading';
        })
        .addCase(fetchUser.fulfilled, (state, action) => {
            state.status = 'succeeded';
            state.data = action.payload;
        })
        .addCase(fetchUser.rejected, (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
        });
    },
});

export default userSlice.reducer;