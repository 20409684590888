import { useState, useRef, useEffect } from 'react';
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from 'react-toastify';

import { styled } from '@mui/material/styles';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
// import OutlinedInput from '@mui/material/OutlinedInput';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

import API from "@/utility/axios";
import Tooltip from "@/components/tooltip";
import Select from "@/components/Select";
import OutlinedInput from "@/components/Input";
import Switch from '@/components/Switch';
import MatIcon from '@/components/MatIcon';
import Button from '@/components/Button';
import { borderRadius, display, height, width } from '@mui/system';

const Container = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: '800px',
    // maxWidth: '800px',
    boxShadow: 'var(--template-shadows-24)'
}));

const Wrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    color: 'var(--bs-modal-color)',
    pointerEvents: 'auto',
    backgroundColor: '#fff',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(0, 0, 0, .175)',
    borderRadius: '.5rem',
    outline: 0
}));

const Header = styled('header')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #c4cada',
    padding: '10px 20px'
}));

export const ModalHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexShrink: 0,
    alignItems: 'center',
    padding: '1rem 1rem',
    borderBottom: '1px solid #c4cada',
    borderTopLeftRadius: 'calc(.5rem - (1px))',
    borderTopRightRadius: 'calc(.5rem - (1px))'
}));

export const ModalBody = styled(Box)(({ theme }) => ({
    position: 'relative',
    padding: '30px'
}));

export const ModalFooter = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexShrink: 0,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: 'calc(1rem - .5rem* .5)',
    backgroundColor: 'var(--bs-modal-footer-bg)',
    borderTop: '1px solid #dee2e6',
    borderBottomRightRadius: 'calc(.5rem - (1px))',
    borderBottomLeftRadius: 'calc(.5rem - (1px))'
}));

const CustomModal = ({ children, title, description, width = 800, open, close }) => {
    const myRef = useRef();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={close}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                }
            }}
        >
            <Fade in={open}>
                <Container sx={{ width: `${width}px`, maxWidth: `${width}px` }}>
                    <Wrapper>
                        {title && (
                            <ModalHeader>
                                <Typography variant="h6" component="h3" sx={{ display: 'flex', flexDirection: 'column', mb: 0, lineHeight: 1.5 }}>
                                    { title }
                                    { description && description }
                                </Typography>
                                <MatIcon
                                    onClick={() => close()}
                                    sx={{
                                        width: 'unset',
                                        height: 'unset',
                                        position: 'absolute',
                                        right: '10px',
                                        top: '10px',
                                        padding: 'calc(1rem * .5) calc(1rem * .5)',
                                        margin: 'calc(-.5 * 1rem) calc(-.5 * 1rem) calc(-.5 * 1rem) auto',
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: '#000',
                                            textDecoration: 'none',
                                            opacity: '.75'
                                        }
                                    }}
                                >
                                    <CloseIcon />
                                </MatIcon>
                            </ModalHeader>
                        )}
                        { children }
                    </Wrapper>
                </Container>
            </Fade>
        </Modal>
    );
}

export const ConfirmModal = ({ title = 'Confirmar', content, open, close, confirm = () => {} }) => {
    return (
        <CustomModal
            title={title}
            open={open}
            close={close}
            width={500}
        >
            <ModalBody>
                <Box>
                    <Typography component="p" variant="subtitle2" sx={{ fontSize: '16px', fontWeight: 500 }}>
                        { content }
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', marginTop: '25px', width: '100%' }}>
                    <Button
                        className="custom"
                        color="success"
                        onClick={() => {
                            close();
                            confirm(true);
                        }}
                        sx={{ width: '100px' }}
                    >
                        <span>
                            <span>Sim</span>
                        </span>
                    </Button>
                    <Button
                        className="custom"
                        color="error"
                        onClick={() => {
                            close();
                            confirm(false);
                        }}
                        sx={{ width: '100px' }}
                    >
                        <span>
                            <span>Não</span>
                        </span>
                    </Button>
                </Box>
            </ModalBody>
        </CustomModal>
    )
}

export const DialogModal = ({ children, title = 'Modal', description, width = 800, open, close }) => {
    const myRef = useRef();

    const containerSx = {
        width: '75%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 'unset',
        right: 0,
        bottom: 0,
        transform: 'unset',
        display: 'flex !important',
        flexDirection: 'column !important',
        gap: '1.5rem',
        overflow: 'auto',
        borderLeftWidth: '1px',
        borderLeftColor: '#3c3c3c',
        backgroundColor: '#ffffff',
        padding: '1.5rem',
        boxShadow: 'var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)'                
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={close}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                }
            }}
        >
            <Fade in={open}>
                <Container sx={{ width: `${width}px`, maxWidth: `${width}px`, ...containerSx }}>
                    <Box sx={{ display: 'flex', maxWidth: '70%', flexDirection: 'column', textAlign: 'left' }}>
                        <Typography variant="h6" component="h2" sx={{ margin: 0, lineHeight: 1.7, fontSize: '1.125rem', fontWeight: 600 }}>
                            { title }
                        </Typography>
                        {description && (
                            <Typography variant="subtitle1" component="p" sx={{ margin: 0, lineHeight: 1.2, fontSize: '.875rem', color: '#9b9b9b', marginTop: 'calc(.5rem* calc(1 - 0))', marginBottom: 'calc(.5rem* 0)' }}>
                                { description }
                            </Typography>
                        )}
                    </Box>
                    { children }
                    <Button
                        onClick={() => {
                            close();
                        }}
                        sx={{
                            position: 'absolute',
                            right: '1.5rem',
                            top: '1.5rem',
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 'calc(0.3rem - 4px)',
                            padding: '0 !important',
                            minWidth: 'unset',
                            backgroundColor: 'transparent !important'
                        }}
                    >
                        <Button sx={{
                            width: '2.25rem',
                            height: '2.25rem',
                            alignItems: 'center',
                            display: 'inline-flex',
                            gap: '.5rem',
                            whiteSpace: 'nowrap',
                            borderRadius: 'calc(0.3rem - 2px)',
                            backgroundColor: '#27272a !important',
                            fontSize: '.875rem',
                            lineHeight: '1.25rem',
                            fontWeight: 500,
                            color: '#eaf5e9',
                            padding: '0 !important',
                            minWidth: 'unset'
                        }}>
                            <CloseIcon sx={{ width: '1rem', height: '1rem' }} />
                        </Button>
                    </Button>
                </Container>
            </Fade>
        </Modal>
    );
}

export default CustomModal;