import './styles.scss';

import React, { useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useAuth } from "../../context/authContext"

// import logoVertical from "../../../public/logo-vertical.png";
import LogoHorizontal from '@/assets/images/logo-horizontal.svg?react';
import LogoIcon from '@/assets/images/logo-icon.svg?react';

const LoadingScreen = ({ loading }) => {
    // loading = true
    return (
        <div className="w-screen h-screen flex content-center absolute" style={{
            zIndex: loading ? '999999' : 'unset',
            display: !loading ? 'none' : 'unset'
        }}>
            <Backdrop
                sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}
            >
                <div className="flex flex-col gap-5 justify-center items-center">
                    <LogoIcon width="70" />
                    {/* <img className="fade-image" src={logoVertical} width={100} /> */}
                    {/* <CircularProgress color="inherit" size={30} /> */}
                    {/*
                    <div style={{"display":"flex","justifyContent":"center","left":"-30px","position":"relative"}}>
                        <div style={{"width":"60px","height":"60px","background":"#B1FFE3","border":"6px solid #003D41","borderRadius":"1000px","zIndex":"5","position":"relative","top":"0","left":"0"}}></div>
                        <div id="circle_1" style={{"width":"60px","height":"60px","background":"#B1FFE3","border":"6px solid #003D41","borderRadius":"1000px","zIndex":"4"}}></div>
                        <div id="circle_2" style={{"width":"60px","height":"60px","background":"#B1FFE3","border":"6px solid #003D41","borderRadius":"1000px","zIndex":"3"}}></div>
                        <div id="circle_3" style={{"width":"60px","height":"60px","background":"#B1FFE3","border":"6px solid #003D41","borderRadius":"1000px","zIndex":"2"}}></div>
                    </div>
                    */}
                </div>
            </Backdrop>
        </div>
    );
};

export default LoadingScreen;
