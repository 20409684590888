import axios from 'axios';

const baseURL = import.meta.env.VITE_API_URL;
const isLocal = import.meta.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'development'

const API = axios.create({
    baseURL: `${isLocal ? 'http://' : 'https://'}${baseURL}${isLocal ? '/development' : '/v1'}`,
    headers: {
        'Content-Type': 'application/json'
    }
});

API.interceptors.request.use((request) => {
    const token = localStorage.getItem("token");
    if (token) {
        request.headers.Authorization = `Bearer ${token}`;
    }

    return request;
})

export default API;